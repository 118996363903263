define("client-base/components/cb-table/cb-delete-cell/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)(
  /*
    <CbDeleteItem @isIcon={{true}} @actionDelete={{fn @actionChangedData @value}} @useDialog={{false}}/>
  */
  {
    "id": "AMK1+NwE",
    "block": "[[[8,[39,0],null,[[\"@isIcon\",\"@actionDelete\",\"@useDialog\"],[true,[28,[37,1],[[30,1],[30,2]],null],false]],null]],[\"@actionChangedData\",\"@value\"],false,[\"cb-delete-item\",\"fn\"]]",
    "moduleName": "client-base/components/cb-table/cb-delete-cell/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});