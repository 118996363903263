define("client-base/components/cb-delete-item/component", ["exports", "@glimmer/component", "@glimmer/tracking", "@ember/object", "client-base/utils/error-handler"], function (_exports, _component, _tracking, _object, _errorHandler) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor, _descriptor2;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  /**
   * This component provides a button for deleting something. A dialog is shown for acknowleding.
   * 
   * @class CbDeleteItem
   * @param {action} actionDelete The action that is called when the delete button is pressed. Should return a promise, so we can do a nice consistent error handling.
   * @param {String} title The title of the dialog.
   * @param {String} size The size of the button - for the allowed values have a look in the bootstrap documentation
   * @param {String} message The message for the dialog.
   * @param {String} warning An optional warning message for the dialog.
   * @param {String} error The error message that is shown if an error occurs.
   * @param {boolean} [useDialog=true]
   * @param {Boolean} isIcon the button is just an icon
   * @param {Boolean} isIconButton a regular button but with just an icon
   * @param {String} icon use this icon
   * @param {String} [pull='right']
   */
  let CbDeleteItem = (_class = class CbDeleteItem extends _component.default {
    constructor() {
      super(...arguments);

      _defineProperty(this, "errorHandler", new _errorHandler.default());

      _initializerDefineProperty(this, "showDialog", _descriptor, this);

      _initializerDefineProperty(this, "isWorking", _descriptor2, this);
    }

    get isIcon() {
      if (!this.args.isIconButton && this.args.icon || this.args.isIcon) return true;
      return false;
    }

    get isIconButton() {
      return this.args.isIconButton ?? false;
    }
    /**
     * set a default icon that can be used with isIcon or isIconButton - this component uses mostly the 'times' icon
     * if you only want to use an icon if it was explicitly set, use @icon instead
     */


    get icon() {
      return this.args.icon ?? 'times';
    }

    get pull() {
      return this.args.pull ?? 'right';
    }

    get pullRight() {
      return this.pull === 'right';
    }

    get pullLeft() {
      return this.pull === 'left';
    }

    get useDialog() {
      return this.args.useDialog ?? true;
    }

    get msgError() {
      return this.args.error || 'Löschen nicht möglich';
    }

    show(event) {
      this.errorHandler.clear();

      if (this.useDialog) {
        this.showDialog = true;
        this.isWorking = false;
      } else {
        this.submit();
      } // when this component is used in a table with selectable rows,
      // the click on this component should not select the row


      event.stopPropagation();
    }

    close() {
      this.errorHandler.clear();
      this.showDialog = false;
      this.isWorking = false;
    }

    async submit() {
      try {
        this.isWorking = true;
        await this.args.actionDelete?.();
        this.close();
      } catch (error) {
        this.errorHandler.handleRestError('delete-item.submit', error, this.msgError);
      } finally {
        this.isWorking = false;
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "showDialog", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "isWorking", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "show", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "show"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "close", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "close"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "submit", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "submit"), _class.prototype)), _class);
  _exports.default = CbDeleteItem;
});